<template>
  <div class="flex flex-col gap-4">
    <Carousel v-if="memos?.data" verticalViewPortHeight="270px" :show-navigators="true" :show-indicators="false"
      ref="carouselRef" :value="memos?.data" :numVisible="1" :numScroll="1" :responsiveOptions="responsiveOptions"
      circular @mouseenter="pauseCarousel" @mouseleave="resumeCarousel">
      <template #item="slotProps">
        <div class="overflow-hidden">
          <MemoPanel :memo="slotProps.data" class="m-2"></MemoPanel>
        </div>
      </template>
    </Carousel>

    <template v-for="page of (articles as unknown)">
      <PagePanel :page="page"></PagePanel>
    </template>
    <div class="more-articles">
      <Button text @click="turnToPages" :icon-pos="'right'">
        <Icon name="icon-park-outline:hand-right" slot="icon"></Icon>更多文章
      </Button>
    </div>
  </div>
</template>
<script lang="ts" setup>
useHead({
  title: '早早集市｜博客站',
  meta: [
    {
      name: 'description',
      content: '前端｜全栈｜前端架构｜Node｜Nuxt3｜Hono｜Bun| 副业 丨早早集市｜博客站｜',
    },
    {
      name: 'keywords',
      content: '早早集市,博客站,前端,前端工程化,前端架构,Node,Nuxt3,Hono,副业',
    },
  ],
  link: [
    {
      rel: 'canonical',
      href: 'https://zzao.club/'
    }
  ]
})
const { $http } = useHttp()
const isHoverMemo = ref(false)
const { setUser, user } = useUser()
// const toast = useToast()
const { disposeError } = useErrorDispose()
const responsiveOptions = ref([
  {
    breakpoint: '1400px',
    numVisible: 1,
    numScroll: 1
  },
  {
    breakpoint: '1199px',
    numVisible: 1,
    numScroll: 1
  },
  {
    breakpoint: '767px',
    numVisible: 1,
    numScroll: 1
  },
  {
    breakpoint: '575px',
    numVisible: 1,
    numScroll: 1
  }
]);
const carouselRef = ref(null)
const { checkDate, updateDateFromNow, checkUpdate, formatDate } = useDayjs();
// const contentQuery = queryContent('post')
const maxPage = 3
interface Page {
  title?: string | undefined;
  _path: string;
  description: string;
  date: string;
  lastmod: string;
  tags?: string[];
  versions?: string[];
}
// console.log(`count`, count, maxPage)
const articles = await queryCollection('content').order('date', 'DESC').limit(5).all()
// const nextPage = () => {
//   navigateTo({ path: '/', query: { page: (page.value + 1) % maxPage } })
// }
const autoplayInterval = computed(() => isHoverMemo.value ? 0 : 5000)
const hoverMemo = (flag: number) => {
  console.log(`flag`, flag)
  isHoverMemo.value = !!flag
}
const pauseCarousel = (event: any) => {
  isHoverMemo.value = true
}
const resumeCarousel = (event: any) => {
  isHoverMemo.value = false
}
const turnToPages = () => {
  navigateTo('/article')
}

// 更改为响应式请求
const { data: memos, error, status } = await $http.get('/api/v1/memos/list', { page: 1, size: 10 }, { server: false })

// watchEffect watch了不干净的东西
watch(error, () => {
  // console.log(`watch error`, error)
  if (error?.value) {
    disposeError(error)
  }
})

</script>
<style lang="less" scoped></style>